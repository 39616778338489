import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { IntegrationService } from './integration.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {

  constructor(
    private router: Router,
    private _integrationService: IntegrationService,
    private _location: Location) {
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  openSameTab(url: string) {
    window.open(url, '_self');
  }

  back() {
    this._location.back();
  }

  navigate(route: string) {
    this.router.navigate([String(route)]);
  }

  navigateCleanHistory(route: string) {
    this.router.navigate([String(route)], { replaceUrl: true });
  }

  navigateWithState(route: string, _state: any) {
    this.router.navigateByUrl(route, { state: _state });
  }

  navigateLoginWithRedirect(redirectURL: string) {
    this.router.navigate(['login'], { queryParams: { redirect: redirectURL } });
  }

  navigateNOL(target?: string) {
    if (target === 'self') {
      this.openSameTab(environment.integration.nolURL);
    } else {
      this.openNewTab(environment.integration.nolURL);
    }
  }

  exitFlow() {
    this.openSameTab(this._integrationService.exitFlowURL());
  }
}
