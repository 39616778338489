import { AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { Observable, Subject, Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { TokenRedirectorService } from 'src/app/core/utils/services/token-redirector.service';
import { IDialogData, NGZDialogService } from 'zumo';

export abstract class InscBase implements OnInit, AfterViewInit {
  private user;
  private tk;
  redirecting = false;
  redirectURL = 'https://www.quiero.com.ar/home';
  projectUrls = {
    help: 'https://www.quiero.com.ar/ayuda',
    ahorro: 'https://www.quiero.com.ar/categorias',
    salidas: 'https://www.quiero.com.ar/categoria/1-HJGJ9LF',
    turismo: 'https://www.quiero.com.ar/resultado-busqueda?search=millas&tb=',
    productos:
      'https://www.quiero.com.ar/resultado-busqueda?search=productos&tb=',
    beneficio:
      'https://www.quiero.com.ar/resultado-busqueda?search=comodin&tb=',
    recargas: 'https://www.quiero.com.ar/resultado-busqueda?search=recarga&tb=',
    terminos_condiciones: 'https://www.quiero.com.ar/ayuda',
  };
  selectedUrl: string;
  identity;

  postQSubject = new Subject();
  helpSubject = new Subject();
  goHomeSubject = new Subject();
  public onActionSubscription: Subscription;
  public onActionAfterClose$: Observable<any>;
  public newAfterclosemodalUnsuscribe = new Subject();
  afterCloseSubjectGeneric = new Subject();
  unsuscribeSubject = new Subject();
  greetCardStyle: any;
  bannerCardStyle: any;
  imgCardStyleA: any;
  imgCardStyleB: any;
  imgCardStyleBody: any;
  bannerCardStyleBody: any;
  unsuscribing = false;
  unsuscribeConfirmed = false;

  maintenanceFlag = false;
  showUnscriptionButton = false;
  reasons;
  bonif;
  shared;
  banners: any;
  constructor(
    protected router: Router,
    protected dialogService: NGZDialogService,
    protected auth: AuthenticationService,
    protected subscriptionService: SubscriptionService,
    protected tkRedirectorService: TokenRedirectorService,
  ) {
    const data = this.router.getCurrentNavigation().extras.state;
    if (!data || data.registered === 'N') {
      this.router.navigateByUrl('');
    }
    this.maintenanceFlag = data.maintenance;
    this.user = data.name;
    this.tk = data.token;
    this.identity = data.payload;
    this.reasons = data.unsubscription_reasons;
    this.bonif = data.bonus;
    this.shared = data.shared;
    this.banners = data.banners;
    this.showUnscriptionButton = data.showUnsubscriptionButton;

    const _reasons = [];
    this.reasons.forEach((reason, index) => {
      _reasons.push({
        id: index,
        text: reason,
        disabled: false,
      });
    });
    this.reasons = _reasons;
  }

  redirectToLink(link) {
    window.location.href = link;
  }

  ngOnInit(): void {
    this.bannerCardStyle = {
      padding: '10px',
      'background-color': 'white',
    };
  }

  ngAfterViewInit(): void {
    this.onActionAfterClose$ = this.postQSubject.asObservable();

    this.onActionSubscription = this.onActionAfterClose$.subscribe(() => {
      this.dialogService.closeDialog();
    });
    this.newAfterclosemodalUnsuscribe.subscribe((data) => {});

    this.postQSubject.asObservable().subscribe(() => {
      this.redirecting = true;
      if (this.tk) {
        this.tkRedirectorService.red(this.tk);
      } else {
        window.location.href = this.redirectURL;
      }
    });
    this.helpSubject.asObservable().subscribe(() => {
      this.redirectToLink(this.selectedUrl);
    });
    this.goHomeSubject.asObservable().subscribe(() => {
      this.router.navigate(['']);
    });
  }

  openUnsuscribeDialog(): void {
    const dialogData: IDialogData = {
      actionsButtons: [
        {
          text: 'Prefiero pensarlo',
          onAction: this.afterCloseSubjectGeneric,
        },
        {
          text: 'Desuscribirme',
          onAction: this.unsuscribeSubject,
        },
      ],
      title: 'Se procederá a la desuscripción del programa Quiero!',
      // tslint:disable-next-line: max-line-length
      paragraph: `Con la baja del servicio podrías perder todos sus puntos si no tenés adherido alguno
      de los productos necesarios para permanecer como usuario de Quiero! según los términos y condiciones del programa.`,
    };
    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }
  openUnsuscribeConfirmedDialog(): void {
    this.unsuscribeConfirmed = true;
    const dialogData: IDialogData = {
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.goHomeSubject,
        },
      ],
      title: 'La desuscripción fue exitosa',
    };
    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }

  openUnsuscribeErrorDialog(): void {
    const dialogData: IDialogData = {
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.goHomeSubject,
        },
      ],
      title: 'Ocurrió un error durante la desuscripción, intentá nuevamente',
    };
    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }

  openMaintenanceDialog(): void {
    // @ts-ignore
    window.dataLayer.push({
      event: 'unsubscription_error_by_maintenance',
      inscription_result: 'error_by_maintenance',
    });
    const dialogData: IDialogData = {
      contentIcon: {
        iconName: 'icon-clock',
      },
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.afterCloseSubjectGeneric,
          data: {},
        },
      ],
      title: 'Esta página está en mantenimiento',
      onAction: this.afterCloseSubjectGeneric,
      paragraph:
        'En este momento, estamos trabajando para mejorar tu experiencia. Reintentá más tarde.',
    };

    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }

  logout() {
    this.auth.logout();
  }

  openRedirectDialog(url?): void {
    this.selectedUrl = url;
    const dialogData: IDialogData = {
      actionsButtons: [
        {
          text: 'Seguir en Naranja',
          onAction: this.afterCloseSubjectGeneric,
        },
        {
          text: 'Entendido',
          onAction: url ? this.helpSubject : this.postQSubject,
        },
      ],
      title: 'Vamos a redirigirte fuera de Naranja',
      paragraph: 'Estás por entrar al sitio de Quiero!',
    };
    this.dialogService.showDialog(dialogData, this.afterCloseSubjectGeneric);
  }

  openHelp(): void {
    this.openRedirectDialog(this.projectUrls.help);
  }

  openAhorros(): void {
    this.openRedirectDialog(this.projectUrls.ahorro);
  }

  openSalidas(): void {
    this.openRedirectDialog(this.projectUrls.salidas);
  }

  openTurismo(): void {
    this.openRedirectDialog(this.projectUrls.turismo);
  }

  openProductos(): void {
    this.openRedirectDialog(this.projectUrls.productos);
  }

  openBeneficio(): void {
    this.openRedirectDialog(this.projectUrls.beneficio);
  }

  openRecargas(): void {
    this.openRedirectDialog(this.projectUrls.recargas);
  }

  openTerminosCondiciones(): void {
    this.openRedirectDialog(this.projectUrls.terminos_condiciones);
  }

  goToQuiero() {
    this.openRedirectDialog();
  }
}
