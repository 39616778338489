import { AfterViewInit, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@goldenfront/core/authentication';
import { Observable, Subject, Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import {
  ICustomDialogOptions,
  IDialogData,
  NGZCustomDialogService,
  NGZDialogService,
} from 'zumo';
import { TermsComponent } from '../../terms/terms.component';

export abstract class NoInscBase implements OnInit, AfterViewInit {
  public user;
  public redirecting = false;
  public subscription;
  public programTerms;
  public legalTerms;
  public acceptedProgramTerms;
  public acceptedLegalTerms;
  public subscriptionSuccess;
  public subscriptionChannel;
  public afterCloseSubject = new Subject();
  public onActionSubscription: Subscription;
  public onActionAfterClose$: Observable<any>;
  public afterCloseSubjectGeneric = new Subject();
  public form: FormGroup;
  public acceptedTerms = false;
  public bonif;
  public channel;
  public propuestaPrecio;
  public propuestaMoneda;
  public propuestaMeses;
  public propuestaPuntos;
  @ViewChild('info') targetEl: ElementRef;
  public visible = false;
  public observer: IntersectionObserver;
  public isLoading = false;
  public disabled = true;
  public maintenanceFlag = false;
  private observables: Observable<any>[] = [];

  constructor(
    protected router: Router,
    protected customDialogService: NGZCustomDialogService,
    protected dialogService: NGZDialogService,
    protected auth: AuthenticationService,
    protected subscriptionService: SubscriptionService,
  ) {
    const data = this.router.getCurrentNavigation().extras.state;
    if (!data || data.registered === 'S') {
      this.router.navigateByUrl('');
    }
    this.maintenanceFlag = data.maintenance;
    this.user = data.name;
    this.bonif = data.bonus;
    this.programTerms = data.condition_terms;
    this.legalTerms = data.legal_terms;
    this.propuestaPrecio = data.propuesta_valor.PRECIO;
    this.propuestaMoneda = data.propuesta_valor.MONEDA;
    this.propuestaMeses = data.propuesta_valor.MESES_BONIFICACION;
    this.propuestaPuntos = data.propuesta_valor.PUNTOS_BIENVENIDA;

    this.observer = new IntersectionObserver(
      (entries) => {
        this.visible = !this.visible;
      },
      { threshold: [0.6] },
    );
  }

  ngOnInit(): void {
    this.onActionAfterClose$ = this.afterCloseSubject.asObservable();
    this.onActionSubscription = this.onActionAfterClose$.subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    const element: Element = document.querySelector('#info');
    this.observer.observe(element);
  }
  openTermsDialog(): void {
    const option: ICustomDialogOptions = {
      onActionClose: this.afterCloseSubjectGeneric,
      data: this.programTerms,
    };
    this.customDialogService.showDialog(
      TermsComponent,
      this.afterCloseSubjectGeneric,
      'Términos y Condiciones',
      option,
    );
  }

  openLegalTermsDialog(): void {
    const option: ICustomDialogOptions = {
      onActionClose: this.afterCloseSubjectGeneric,
      data: this.legalTerms,
    };
    this.customDialogService.showDialog(
      TermsComponent,
      this.afterCloseSubjectGeneric,
      'Términos y Condiciones',
      option,
    );
  }

  openSuccessDialog(): void {
    // @ts-ignore
    window.dataLayer.push({
      event: 'inscription_success',
      inscription_result: 'success',
    });
    const dialogData: IDialogData = {
      contentIcon: {
        iconName: 'icon-check',
      },
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.afterCloseSubject,
          data: {},
        },
      ],
      title: 'Inscripción Correcta',
      onAction: this.afterCloseSubject,
      paragraph: 'La inscripción al programa fué exitosa',
    };

    this.dialogService.showDialog(dialogData, this.afterCloseSubject);
  }

  openErrorDialog(): void {
    // @ts-ignore
    window.dataLayer.push({
      event: 'inscription_error',
      inscription_result: 'error',
    });
    const dialogData: IDialogData = {
      contentIcon: {
        iconName: 'icon-cross',
      },
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.afterCloseSubject,
          data: {},
        },
      ],
      title: 'Fallo en la inscripción',
      onAction: this.afterCloseSubject,
      paragraph:
        'La inscripción al programa no fué exitosa, intente nuevamente',
    };

    this.dialogService.showDialog(dialogData, this.afterCloseSubject);
  }

  openMaintenanceDialog(): void {
    // @ts-ignore
    window.dataLayer.push({
      event: 'inscription_error_by_maintenance',
      inscription_result: 'error_by_maintenance',
    });
    const dialogData: IDialogData = {
      contentIcon: {
        iconName: 'icon-cross',
      },
      actionsButtons: [
        {
          text: 'Entendido',
          onAction: this.afterCloseSubject,
          data: {},
        },
      ],
      title: 'Esta página está en mantenimiento',
      onAction: this.afterCloseSubject,
      paragraph:
        'En este momento, estamos trabajando para mejorar tu experiencia. Reintentá más tarde.',
    };

    this.dialogService.showDialog(dialogData, this.afterCloseSubject);
  }

  agreeLegalTerms(event) {
    this.acceptedLegalTerms = event.target.checked;
    this.acceptedTerms = this.acceptedLegalTerms && this.acceptedProgramTerms;
  }

  agreeProgramTerms(event) {
    this.acceptedProgramTerms = event.target.checked;
    this.acceptedTerms = this.bonif
      ? this.acceptedProgramTerms
      : this.acceptedLegalTerms && this.acceptedProgramTerms;
  }

  scroll() {
    this.targetEl.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }

  inscribe() {
    this.isLoading = true;
    const channel = sessionStorage.getItem('channel');
    this.channel = channel;
    if (!this.maintenanceFlag) {
      this.subscriptionService
        .subscribeToChannel(this.channel, this.legalTerms)
        .subscribe(
          (res) => {
            this.subscriptionSuccess = res.success;
            this.isLoading = false;
            if (this.subscriptionSuccess) {
              this.openSuccessDialog();
              sessionStorage.removeItem('channel');
            } else {
              this.openErrorDialog();
            }
          },
          (err) => {
            this.openErrorDialog();
          },
        );
    } else {
      this.openMaintenanceDialog();
      this.isLoading = false;
    }
  }

  redirect() {
    window.location.href = 'https://www.naranjax.com/promociones/todas';
  }
}
